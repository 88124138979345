<template>
  <div class="pageContol studentList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">培训管理</a>
          <i>></i>
          <a href="javascript:;" @click="toOrganizationList" class="cur-a">机构列表</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()" class="cur-a">开班记录</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">学员列表</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="seachStyle">
          <el-row :gutter="20">
            <el-col :sm="12" :md="8" :lg="5" class="searchList">
              <span class="listLabel">姓名:</span>
              <el-input
                class="listInput"
                v-model="searchData.userName"
                placeholder="请输入姓名"
                size="small"
                clearable
              />
            </el-col>

            <el-col :sm="12" :md="8" :lg="5" class="searchList">
              <span class="listLabel">身份证号:</span>
              <el-input
                class="listInput"
                v-model="searchData.idcard"
                placeholder="请输入身份证号"
                size="small"
                clearable
              />
            </el-col>

            <el-col :sm="12" :md="8" :lg="5" class="searchList">
              <span class="listLabel">电话:</span>
              <el-input
                class="listInput"
                v-model="searchData.mobile"
                placeholder="请输入电话"
                size="small"
                clearable
              />
            </el-col>

            <el-col :sm="12" :md="8" :lg="5" class="searchList">
              <span class="listLabel">学习状态:</span>
              <el-select
                class="listInput"
                size="small"
                v-model="searchData.classStatus"
                placeholder="请选择学习状态"
                clearable
              >
                <el-option
                  v-for="item in classStatusList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-col>

            <el-col :sm="12" :md="8" :lg="4" class="searchList">
              <div class="listBtn">
                <el-button type="primary" class="listBtnS" round @click="getData()">查询</el-button>
                <el-button type="primary" class="listBtnS" round @click="doExport(1)">导出</el-button>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column label="序号" align="center" type="index" :index="indexMethod" fixed/>
              <el-table-column label="姓名" align="left" show-overflow-tooltip prop="userName" />
              <el-table-column label="性别" align="left" show-overflow-tooltip prop="sex" />
              <el-table-column label="年龄" align="right" show-overflow-tooltip prop="age" />
              <el-table-column label="身份证号" align="left" show-overflow-tooltip prop="idcard" minWidth="150"/>
              <el-table-column label="手机号码" align="left" show-overflow-tooltip prop="mobile" minWidth="120"/>
              <el-table-column label="学习进度" align="right" show-overflow-tooltip prop="progress" />
              <el-table-column
                label="学习状态"
                align="center"
                prop="completeState"
              >
               <template slot-scope="scope">
                  <span  v-if="scope.row.completeState!='40'">{{ $setDictionary("LEARNINGSTATE", scope.row.completeState) }}</span>
                  <el-tooltip v-else
                    class="item"
                    effect="dark"
                    :content="scope.row.notCompleteReasons"
                    placement="top"
                  >
                    <span style="cursor: pointer; display: flex;justify-content: center;">{{ $setDictionary("LEARNINGSTATE", scope.row.completeState)}}</span>
                  </el-tooltip>
                </template>
              </el-table-column>
              <el-table-column
                label="完成学时"
                align="right"
                show-overflow-tooltip
                prop="studyLessonNum"
              />
              <el-table-column
                label="剩余学时"
                align="right"
                show-overflow-tooltip
                prop="residueLessonNum"
              />
              <el-table-column
                label="监管点名已完成次数"
                align="right"
                show-overflow-tooltip
                prop="govRollCompleteNum"
              />
              <el-table-column
                label="监管点名剩余次数"
                align="right"
                show-overflow-tooltip
                prop="govRollResidueNum"
              />
              <el-table-column
                label="监管点名超时次数"
                align="right"
                show-overflow-tooltip
                prop="govRollTimeoutNum"
              />
              <el-table-column
                label="机构点名已完成次数"
                align="right"
                show-overflow-tooltip
                prop="orgRollCompleteNum"
              />
              <el-table-column
                label="机构点名剩余次数"
                align="right"
                show-overflow-tooltip
                prop="orgRollResidueNum"
              />
              <el-table-column
                label="机构点名超时次数"
                align="right"
                show-overflow-tooltip
                prop="orgRollTimeoutNum"
              />
              <el-table-column
                label="随机点名已完成次数"
                align="right"
                show-overflow-tooltip
                prop="randomRollCompleteNum"
              />
              <el-table-column
                label="随机点名剩余次数"
                align="right"
                show-overflow-tooltip
                prop="randomRollResidueNum"
              />
              <el-table-column
                label="随机点名失败次数"
                align="right"
                show-overflow-tooltip
                prop="randomRollTimeoutNum"
              />

              <el-table-column
                label="点名状态"
                align="center"
                show-overflow-tooltip
                prop="rollCallState"
              />

              <el-table-column label="操作" align="center" width="140px" fixed="right">
                <div slot-scope="scope" class="flexcc">
                  <el-button type="text" size="mini" @click="open(scope.row,1)">认证统计</el-button>
                  <el-button type="text" size="mini" @click="open(scope.row,2)">学习记录</el-button>
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import moment from "moment";

export default {
  name: "sjzRegulator/studentList",
  components: {
    Empty,
    PageNum
  },
  mixins: [List],
  data() {
    return {
      searchData: {
        projectId: this.$route.query.projectId, //班级id
        userName: "", //姓名
        idcard: "", //身份证号
        mobile: "", //手机号
        classStatus: "" //班级状态
      },
      classStatusList: [], //
      areatreeList: [], //行政区域list
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true
      }
    };
  },
  created() {},
  watch: {},
  computed: {},
  mounted() {
    this.init();
  },
  methods: {
     // 导出
    doExport(pageNum = 1) {

     const params = {
        projectId: this.$route.query.projectId,
        pageSize: this.pageSize,
        pageNum
      };
      if (this.searchData.userName) {
        params.userName = this.searchData.userName;
      }
      if (this.searchData.idcard) {
        params.idcard = this.searchData.idcard;
      }
      if (this.searchData.mobile) {
        params.mobile = this.searchData.mobile;
      }
      if (this.searchData.classStatus) {
        params.completeState = this.searchData.classStatus;
      }
      this.$post("/gov/roll/call/user/export", params).then(res => {
        if (res.status == "0") {
          window.open(res.data.url);
        }
      });
    },
    getLearningstateList() {
      const classStatusList = this.$setDictionary("LEARNINGSTATE", "list");
      const list = [];
     
      for (const key in classStatusList) {
        list.push({
          value: key,
          label: classStatusList[key]
        });
      }
      
      this.classStatusList = list;
    },
    init() {
      this.getareatree();
      this.getTableHeight();
      this.getData(-1);
      this.getLearningstateList();
    },
    //机构列表
    toOrganizationList() {
      this.$router.push({
        path: "/web/SupervisionEnd/sjzRegulator/organizationList"
      });
    },
    //跳转
    open(row, type) {
      if (type == "1") {
        //认证统计

        this.$router.push({
          path: "/web/SupervisionEnd/sjzRegulator/rollStatistics",
          query: {
            projectId: this.$route.query.projectId,
            userId:row.userId
          }
        });
      }
      if (type == "2") {
        //学习记录
        this.$router.push({
          path: "/web/SupervisionEnd/sjzRegulator/studyRecord",
          query: {
             projectId: this.$route.query.projectId,
            userId:row.userId
          }
        });
      }
    },
    getData(pageNum = 1) {
      const params = {
        projectId: this.$route.query.projectId,
        pageSize: this.pageSize
      };
      if (this.searchData.userName) {
        params.userName = this.searchData.userName;
      }
      if (this.searchData.idcard) {
        params.idcard = this.searchData.idcard;
      }
      if (this.searchData.mobile) {
        params.mobile = this.searchData.mobile;
      }
      if (this.searchData.classStatus) {
        params.completeState = this.searchData.classStatus;
      }
      this.doFetch({
        url: "/gov/roll/call/user/page-list",
        params,
        pageNum
      });
    },
    //获取行政区划
    getareatree() {
      this.$post("/gov/area/tree").then(ret => {
        this.areatreeList = ret.data;
      });
    },

    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 3.25) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16;
      }
      if (page) {
        tHeight -= 40;
      }
      this.tableHeight = tHeight;
    }
  }
};
</script>
<style lang="less">
</style>
